import React from "react"

import Layout from "../components/layout/layout.js"
import SEO from "../components/seo/seo"


const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h1>This is where my contact information lives</h1>
  </Layout>
)

export default ContactPage
